import { getJWT } from '../utils/jwt';

const API_URL = process.env.REACT_APP_API_URL;

export const getDatapoints = () =>
  fetch(`${API_URL}/config/datapoints`, {
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${getJWT()}`,
    },
  });
