import React, { useState, useEffect } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { useSelector, useDispatch } from 'react-redux';
import { matchPath } from 'react-router';
import { NavLink } from 'react-router-dom';
import { Layout, Menu, Select, Switch, Tooltip } from 'antd';
import {
  ExclamationCircleOutlined,
  FileTextOutlined,
  FundOutlined,
  LogoutOutlined,
  ProjectOutlined,
  StockOutlined,
  MailOutlined,
  QrcodeOutlined,
  SettingOutlined,
  TeamOutlined,
} from '@ant-design/icons';
import _ from 'lodash';

import SelectProjectPage from './scenes/SelectProjectPage';
import { removeJWT } from '../../utils/jwt';
import * as projectApi from '../../api/project';
import * as fillDeviceApi from '../../api/fillDevice';
import * as configApi from '../../api/config';
import {
  updateAvailableProjects,
  selectProject,
  updateAvailableFloors,
  selectFloor,
  updateProjectData,
  updateProjectDataLoadingStatus,
  updateFillDevices,
  logout,
} from '../../redux/actions/user';
import { updateDatapoints, collapseSidebar } from '../../redux/actions/config';

import './style.scss';

const { Content, Header, Sider } = Layout;
const { Option } = Select;
const MenuItem = Menu.Item;

const DashboardPage = (props) => {
  const dispatch = useDispatch();
  const userName = useSelector((state) => state.user.username);
  const isBelimoUser = useSelector((state) => state.user.isBelimoUser);
  const availableProjects = useSelector((state) => state.user.availableProjects);
  const selectedProjectId = useSelector((state) => state.user.selectedProjectId);
  const projectData = useSelector((state) => state.user.projectData);
  const availableFloors = useSelector((state) => state.user.availableFloors);
  const selectedFloorId = useSelector((state) => state.user.selectedFloorId);
  const collapsed = useSelector((state) => state.config.isSidebarCollapsed);
  const { children, history, location } = props;
  const match = matchPath(location.pathname, {
    path: '/dashboard/:projectId/:dashboardPage',
    exact: true,
    strict: false,
  });
  const dashboardPage = match ? match.params.dashboardPage : undefined;
  const isProjectMonitoringPage =
    location.pathname === '/dashboard' ||
    location.pathname === '/dashboard/' ||
    (match && match.params.projectId !== undefined);
  const projectId = match && match.params.projectId;

  const fetchDatapoints = () => {
    return configApi
      .getDatapoints()
      .then((res) => res.json())
      .then((json) => {
        dispatch(updateDatapoints(json));
      });
  };

  const fetchProjects = () => {
    return projectApi
      .getProjects()
      .then((res) => res.json())
      .then((json) => {
        dispatch(updateAvailableProjects(json));
      });
  };

  const fetchProject = (projectId) => {
    dispatch(updateProjectDataLoadingStatus(true));
    return projectApi
      .getProject(projectId)
      .then((res) => res.json())
      .then((json) => {
        dispatch(updateProjectData(json));
        dispatch(updateProjectDataLoadingStatus(false));
      });
  };

  const fetchProjectZoneDevices = (projectId) => {
    return fillDeviceApi
      .getFillDevices(projectId)
      .then((res) => res.json())
      .then((json) => {
        const groupedFillDevices = _.groupBy(json, 'zoneId');
        dispatch(updateFillDevices(groupedFillDevices));
      });
  };

  const handleLogout = (e) => {
    const { history } = props;
    removeJWT();
    dispatch(logout());
    history.push('/login');
  };

  const renderProjectMonitoringHeader = () => {
    return (
      <div className="header-left">
        <Select
          placeholder="Project"
          dropdownMatchSelectWidth={false}
          value={selectedProjectId}
          onChange={(value) => dispatch(selectProject(value))}
          size="small"
        >
          {_.map(availableProjects, (project) => (
            <Option key={project.id} value={project.id}>
              {project.displayName}
            </Option>
          ))}
        </Select>
        <Select
          placeholder="Floor"
          dropdownMatchSelectWidth={false}
          value={selectedFloorId}
          onChange={(value) => dispatch(selectFloor(value))}
          size="small"
        >
          {_.map(projectData, (floor) => (
            <Option key={floor.id} value={floor.id}>
              {floor.name}
            </Option>
          ))}
        </Select>
      </div>
    );
  };

  useEffect(() => {
    fetchDatapoints();
    fetchProjects().then(() => {
      if (match && match.params) {
        dispatch(selectProject(match.params.projectId));
      }
    });
  }, []);

  useEffect(() => {
    if (selectedProjectId) {
      fetchProject(selectedProjectId);
      fetchProjectZoneDevices({ project_id: selectedProjectId });
      history.push(`/dashboard/${selectedProjectId}/${dashboardPage}`);
    }
  }, [selectedProjectId]);

  useDeepCompareEffect(() => {
    if (projectData && projectData.length > 0) {
      dispatch(selectFloor(projectData[0].id));
    }
  }, [projectData]);

  useDeepCompareEffect(() => {
    if (availableProjects.length > 0) {
      dispatch(selectProject(availableProjects[0].id));
    }
  }, [availableProjects]);

  return (
    <Layout className="DashboardPage">
      <Header>
        {isProjectMonitoringPage && renderProjectMonitoringHeader()}
        <div className="profile-menu">
          <div className="username">{userName}</div>
          <Tooltip placement="topRight" title="Project Monitoring" arrowPointAtCenter>
            <ProjectOutlined
              className={{ selected: isProjectMonitoringPage }}
              onClick={() => {
                if (!isProjectMonitoringPage) {
                  if (selectedProjectId) {
                    history.push(`/dashboard/${selectedProjectId}/${dashboardPage}`);
                  } else {
                    history.push(`/dashboard`);
                  }
                }
              }}
            />
          </Tooltip>
          {/* {isBelimoUser && (
            <Tooltip placement="topRight" title="Subordinate Management" arrowPointAtCenter>
              <TeamOutlined
                className={{ selected: location.pathname === '/dashboard/suborindate-management' }}
                onClick={() => history.push('/dashboard/suborindate-management')}
              />
            </Tooltip>
          )} */}
          {/* <Tooltip title="Config">
            <SettingOutlined
              className={{ selected: location.pathname === '/dashboard/config' }}
              onClick={() => history.push('/dashboard/config')}
            />
          </Tooltip> */}
          <Tooltip placement="topRight" title="Logout" arrowPointAtCenter>
            <LogoutOutlined onClick={handleLogout} />
          </Tooltip>
        </div>
      </Header>
      {isProjectMonitoringPage && selectedProjectId !== undefined ? (
        <Layout>
          <Sider collapsible collapsed={collapsed} onCollapse={() => dispatch(collapseSidebar(!collapsed))}>
            <div className="logo" />
            <Menu selectedKeys={[location.pathname]} mode="inline">
              <MenuItem key={`/dashboard/${projectId}/summary`}>
                <NavLink to={`/dashboard/${projectId}/summary`}>
                  <FileTextOutlined />
                  <span>Summary</span>
                </NavLink>
              </MenuItem>
              <MenuItem key={`/dashboard/${projectId}/data`}>
                <NavLink to={`/dashboard/${projectId}/data`}>
                  <StockOutlined />
                  <span>Raw Data (Live)</span>
                </NavLink>
              </MenuItem>
              <MenuItem key={`/dashboard/${projectId}/historical-data`} disabled>
                <NavLink to={`/dashboard/${projectId}/historical-data`}>
                  <FundOutlined />
                  <span>Data Chart</span>
                </NavLink>
              </MenuItem>
              <MenuItem key={`/dashboard/${projectId}/alerts`} disabled>
                <NavLink to={`/dashboard/${projectId}/alerts`}>
                  <ExclamationCircleOutlined />
                  <span>Alerts</span>
                </NavLink>
              </MenuItem>
              <MenuItem key={`/dashboard/${projectId}/tickets`}>
                <NavLink to={`/dashboard/${projectId}/tickets`}>
                  <MailOutlined />
                  <span>Tickets</span>
                </NavLink>
              </MenuItem>
              <MenuItem key={`/dashboard/${projectId}/qr-stickers`}>
                <NavLink to={`/dashboard/${projectId}/qr-stickers`}>
                  <QrcodeOutlined />
                  <span>QR Stickers</span>
                </NavLink>
              </MenuItem>
            </Menu>
          </Sider>
          <Layout>
            <Content>{children}</Content>
          </Layout>
        </Layout>
      ) : (
        <Layout>
          <Content>
            <Content>{children}</Content>
          </Content>
        </Layout>
      )}
    </Layout>
  );
};

export default DashboardPage;
