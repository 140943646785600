import { getJWT } from '../utils/jwt';
import queryStringBuilder from '../utils/queryStringBuilder';

const API_URL = process.env.REACT_APP_API_URL;

export const getTicket = (ticketId) =>
  fetch(`${API_URL}/tickets/${ticketId}`, {
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${getJWT()}`,
    },
  });

export const getTickets = (queries) =>
  fetch(`${API_URL}/tickets${queryStringBuilder(queries)}`, {
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${getJWT()}`,
    },
  });

export const followUpTicket = (ticketId) =>
  fetch(`${API_URL}/tickets/${ticketId}/follow-up`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${getJWT()}`,
    },
  });

export const updateTicketProcess = (ticketId, body) =>
  fetch(`${API_URL}/tickets/${ticketId}/update-process`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${getJWT()}`,
    },
    body: JSON.stringify(body),
  });
