import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { Table } from 'antd';
import numeral from 'numeral';
import _ from 'lodash';

import { getWSUrl } from '../../../../../../utils/ws';
import { getJWT } from '../../../../../../utils/jwt';

import './style.scss';

const WS_URL = getWSUrl();

const DataPage = (props) => {
  const selectedProjectId = useSelector((state) => state.user.selectedProjectId);
  const projectData = useSelector((state) => state.user.projectData);
  const isLoadingProjectData = useSelector((state) => state.user.isLoadingProjectData);
  const fillDevices = useSelector((state) => state.user.fillDevices);
  const [data, setData] = useState([]);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  const updateData = (newDeviceState) => {
    setData((prevData) =>
      _.map(prevData, (room) => {
        return {
          id: room.id,
          name: room.name,
          children: _.map(room.children, (device) => {
            let deviceData = device;

            if (device.cloudId === newDeviceState.deviceId) {
              deviceData = {
                ...deviceData,
                ...newDeviceState,
              };
            }

            return deviceData;
          }),
        };
      })
    );
  };

  const valueFormatter = (text, record) => {
    if (record.isDeviceSlot) {
      return text !== undefined ? numeral(text).format('0.[00]') : '-';
    } else {
      return '';
    }
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      align: 'left',
      fixed: 'left',
      width: 150,
      render: (text, record) => <div className="child-name">{text}</div>,
    },
    {
      title: 'System Mode',
      dataIndex: 'D45EuDN7.1',
      key: 'D45EuDN7.1',
      align: 'center',
      render: (value, record) => {
        if (record.isDeviceSlot) {
          const mapping = [
            {
              label: 'OFF',
              value: 1,
            },
            {
              label: 'active',
              value: 2,
            },
            {
              label: 'ECO',
              value: 3,
            },
            {
              label: 'boost',
              value: 4,
            },
          ];
          const target = _.find(mapping, { value });
          return value !== undefined ? target.label || value : '-';
        } else {
          return '';
        }
      },
    },
    {
      title: 'Cur. CO2 (ppm)',
      dataIndex: 'spaceCo2',
      key: 'D45EuDN7.77',
      align: 'center',
      render: valueFormatter,
    },
    {
      title: 'Cur. Temp (°C)',
      dataIndex: 'spaceTemp',
      key: 'spaceTemp',
      align: 'center',
      render: valueFormatter,
    },
    {
      title: 'Target Setpoint (°C)',
      dataIndex: 'occCoolingSetPoint',
      key: 'occCoolingSetPoint',
      align: 'center',
      render: valueFormatter,
    },
    {
      title: 'Damper Position (%)',
      dataIndex: 'damper1Free',
      key: 'damper1Free',
      align: 'center',
      render: valueFormatter,
    },
  ];

  useDeepCompareEffect(() => {
    if (selectedProjectId) {
      let newExpandedRowKeys = [];
      const ws = new WebSocket(`${WS_URL}?token=${getJWT()}&project_id=${selectedProjectId}`);
      ws.addEventListener('message', (event) => {
        let json = {};
        try {
          json = JSON.parse(event.data);
          if (json.type === 'DeviceUpdate') {
            updateData(json);
          }
        } catch (error) {}
      });

      setData(
        _.flatMap(projectData, (floor) => {
          return _.map(floor.rooms, (room) => {
            const belimoDevices = _.map(room.devices, (device) => ({
              isDeviceSlot: true,
              id: device.id,
              name: device.name,
              cloudId: device.cloudId,
            }));
            const fillDevicesInZone = _.map(fillDevices[room.id], (device) => ({
              isDeviceSlot: true,
              id: device.id,
              name: device.name,
              fillId: device.deviceId,
            }));
            newExpandedRowKeys.push(room.id);

            return {
              id: room.id,
              name: room.name,
              children: _.concat(belimoDevices, fillDevicesInZone),
            };
          });
        })
      );
      setExpandedRowKeys(newExpandedRowKeys);

      return () => {
        ws.close();
      };
    }
  }, [projectData]);

  return (
    <div className="DataPage">
      <div className="main-section">
        <h2>Engineering Live Data</h2>
        <Table
          rowKey={'id'}
          columns={columns}
          size="small"
          expandable={{
            expandedRowKeys,
            onExpand: (expanded, record) => {
              if (expanded) {
                setExpandedRowKeys(expandedRowKeys.concat([record.id]));
              } else {
                setExpandedRowKeys(expandedRowKeys.filter((item) => item !== record.id));
              }
            },
          }}
          dataSource={data}
          scroll={{ x: 640, y: '62vh' }}
          pagination={false}
          loading={isLoadingProjectData}
        />
      </div>
    </div>
  );
};

export default DataPage;
