import { getJWT } from '../utils/jwt';
import queryStringBuilder from '../utils/queryStringBuilder';

const API_URL = process.env.REACT_APP_API_URL;

export const getProfile = () =>
  fetch(`${API_URL}/user/profile`, {
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${getJWT()}`,
    },
  });

// Subordinates API

export const getSubordinates = () =>
  fetch(`${API_URL}/user/subordinates`, {
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${getJWT()}`,
    },
  });

export const getSubordinate = (subordinateId) =>
  fetch(`${API_URL}/user/subordinates/${subordinateId}`, {
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${getJWT()}`,
    },
  });

export const createSubordinate = (subordinate) =>
  fetch(`${API_URL}/user/subordinates`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${getJWT()}`,
    },
    body: JSON.stringify(subordinate),
  });

export const updateSubordinate = (subordinateId, subordinate) =>
  fetch(`${API_URL}/user/subordinates/${subordinateId}`, {
    method: 'PUT',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${getJWT()}`,
    },
    body: JSON.stringify(subordinate),
  });
  
export const deleteSubordinate = (subordinateId) =>
  fetch(`${API_URL}/user/subordinates/${subordinateId}`, {
    method: 'DELETE',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${getJWT()}`,
    },
  });

export const batchDeleteSubordinates = (subordinateIds) =>
  fetch(`${API_URL}/user/subordinates/batch-delete`, {
    method: 'DELETE',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${getJWT()}`,
    },
    body: JSON.stringify({ subordinateIds }),
  });

// Parameter Lists API

  export const getParameterLists = (queries) =>
  fetch(`${API_URL}/user/parameter-lists${queryStringBuilder(queries)}`, {
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${getJWT()}`,
    },
  });

export const getParameterList = (parameterListId) =>
  fetch(`${API_URL}/user/parameter-lists/${parameterListId}`, {
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${getJWT()}`,
    },
  });

export const createParameterList = (parameterList) =>
  fetch(`${API_URL}/user/parameter-lists`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${getJWT()}`,
    },
    body: JSON.stringify(parameterList),
  });

export const updateParameterList = (parameterListId, parameterList) =>
  fetch(`${API_URL}/user/parameter-lists/${parameterListId}`, {
    method: 'PUT',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${getJWT()}`,
    },
    body: JSON.stringify(parameterList),
  });
  
export const deleteParameterList = (parameterListId) =>
  fetch(`${API_URL}/user/parameter-lists/${parameterListId}`, {
    method: 'DELETE',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${getJWT()}`,
    },
  });

export const batchDeleteParameterLists = (parameterListIds) =>
  fetch(`${API_URL}/user/parameter-lists/batch-delete`, {
    method: 'DELETE',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${getJWT()}`,
    },
    body: JSON.stringify({ parameterListIds }),
  });
