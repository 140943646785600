import React, { useState } from 'react';
import { AutoComplete, Button, Form, Input, Modal, Radio, Spin } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import moment from 'moment';
import _ from 'lodash';

import * as ticketController from '../../../../../../../../api/ticket';
import './style.scss';
import { useEffect } from 'react';

const FormItem = Form.Item;
const RadioGroup = Radio.Group;

const TicketCardModal = (props) => {
  const { visible, zoneList, selectedTicketId, afterSubmit } = props;
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [ticket, setTicket] = useState({});
  const [message, setMessage] = useState(ticket.message || '');
  const { createdAt, issuer, zoneId, ticketProcesses } = ticket;
  const zone = _.find(zoneList, { value: zoneId });

  const MESSAGE_MAX_LENGTH = 30;
  const presetResponseOptions = _.map(['Request received.', 'Temperature adjusted.'], (data) => ({
    label: data,
    value: data,
  }));

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 12 },
    },
  };

  const getTicket = (ticketId) => {
    ticketController
      .getTicket(ticketId)
      .then((res) => res.json())
      .then((json) => setTicket(json));
  };

  const handleSubmit = (values) => {
    setIsLoading(true);

    ticketController
      .updateTicketProcess(selectedTicketId, values)
      .then((res) => res.json())
      .then((json) => {
        setIsLoading(false);
        afterSubmit();
      });
  };

  useEffect(() => {
    if (visible) {
      if (selectedTicketId !== undefined) {
        getTicket(selectedTicketId);
      } else {
        form.resetFields();
      }
    } else {
      form.resetFields();
    }
  }, [visible]);

  return (
    <Modal
      centered
      forceRender
      className="TicketCardModal"
      width="60%"
      style={{ pointerEvents: isLoading ? 'none' : '' }}
      maskClosable={!isLoading}
      title={null}
      footer={null}
      {...props}
    >
      <Spin spinning={isLoading} tip="Processing...">
        <div className="TicketCard">
          <div className="ticket-icon">
            <UserOutlined />
          </div>
          <div className="ticket-date">{moment(createdAt).format('DD/MM/YY')}</div>
          <div className="ticket-user">{issuer ? issuer.name : 'Guest'}</div>
          <div className="ticket-zone">{zone ? zone.label : zoneId}</div>
          <div className="ticket-progress-list">
            {_.map(ticketProcesses, (step, i) => (
              <div className="ticket-progress" key={i}>
                <div className="progress-time">{moment(step.createdAt).format('DD/MM HH:mm')}</div>
                <div className="progress-message">{step.message}</div>
              </div>
            ))}
          </div>
        </div>
        <div className="form-section">
          <p className="sub-title">Add new progress</p>
          <Form form={form} onFinish={handleSubmit} initialValues={{ isCompleted: false }}>
            <FormItem
              {...formItemLayout}
              label="Message"
              name="message"
              rules={[{ required: true, message: 'This field is required.' }]}
            >
              <AutoComplete options={presetResponseOptions} onChange={(value) => setMessage(value)}>
                <Input maxLength={MESSAGE_MAX_LENGTH} suffix={`${message.length}/${MESSAGE_MAX_LENGTH}`} />
              </AutoComplete>
            </FormItem>
            <FormItem
              {...formItemLayout}
              label="Close this request?"
              name="isCompleted"
              rules={[{ required: true, message: 'This field is required.' }]}
            >
              <RadioGroup>
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </RadioGroup>
            </FormItem>
            <div className="footer">
              <Button type="primary" size="large" htmlType="submit">
                Save
              </Button>
            </div>
          </Form>
        </div>
      </Spin>
    </Modal>
  );
};

export default TicketCardModal;
