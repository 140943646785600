import React from 'react';
import { Router, Route, Redirect, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import PrivateRoute from './routes/PrivateRoute';

import LoginPage from './scenes/LoginPage';
import QRStickerPage from './scenes/QRStickerPage';
import DashboardPage from './scenes/DashboardPage';
import SubordinarateManagementPage from './scenes/DashboardPage/scenes/SubordinarateManagementPage';
import FMPage from './scenes/DashboardPage/scenes/FMPage';
import DataPage from './scenes/DashboardPage/scenes/FMPage/scenes/DataPage';
import HistoricalDataPage from './scenes/DashboardPage/scenes/FMPage/scenes/HistoricalDataPage';
import AlertManagementPage from './scenes/DashboardPage/scenes/FMPage/scenes/AlertManagementPage';
import ExternalSensorManagementPage from './scenes/DashboardPage/scenes/FMPage/scenes/ExternalSensorManagementPage';
import DSPage from './scenes/DashboardPage/scenes/DSPage';
import SummaryPage from './scenes/DashboardPage/scenes/DSPage/scenes/SummaryPage';
import TicketPage from './scenes/DashboardPage/scenes/DSPage/scenes/TicketPage';
import QRStickerManagementPage from './scenes/DashboardPage/scenes/DSPage/scenes/QRStickerManagementPage';
import SelectProjectPage from './scenes/DashboardPage/scenes/SelectProjectPage';

const history = createBrowserHistory();

// const fmDashboardSection = ({ match, ...rest }) => (
//   <FMPage match={match} {...rest}>
//     <Switch>
//       <Route exact path={`${match.url}/data`} component={DataPage} />
//       <Route exact path={`${match.url}/historical-data`} component={HistoricalDataPage} />
//       <Route exact path={`${match.url}/alerts`} component={AlertManagementPage} />
//       <Route exact path={`${match.url}/external-sensors`} component={ExternalSensorManagementPage} />
//       <Redirect to={`${match.url}/data`} />
//     </Switch>
//   </FMPage>
// );

// const dsDashboardSection = ({ match, ...rest }) => (
//   <DSPage match={match} {...rest}>
//     <Switch>
//       <Route exact path={`${match.url}/summary`} component={SummaryPage} />
//       <Route exact path={`${match.url}/tickets`} component={TicketPage} />
//       <Route exact path={`${match.url}/qr-stickers`} component={QRStickerManagementPage} />
//       <Redirect to={`${match.url}/summary`} />
//     </Switch>
//   </DSPage>
// );

const projectSection = ({ match, ...rest }) => (
  <Switch>
    <Route exact path={`${match.url}/summary`} component={SummaryPage} />
    <Route exact path={`${match.url}/tickets`} component={TicketPage} />
    <Route exact path={`${match.url}/qr-stickers`} component={QRStickerManagementPage} />
    <Route exact path={`${match.url}/data`} component={DataPage} />
    <Route exact path={`${match.url}/historical-data`} component={HistoricalDataPage} />
    <Route exact path={`${match.url}/alerts`} component={AlertManagementPage} />
    <Route exact path={`${match.url}/external-sensors`} component={ExternalSensorManagementPage} />
    <Redirect to={`${match.url}/summary`} />
  </Switch>
);

const dashboardSection = ({ match, ...rest }) => (
  <DashboardPage match={match} {...rest}>
    <Switch>
      <Route exact path={`${match.url}/`} component={SelectProjectPage} />
      <Route exact path={`${match.url}/suborindate-management`} component={SubordinarateManagementPage} />
      <Route exact path={`${match.url}/config`} component={SelectProjectPage} />
      <Route path={`${match.url}/:projectId`} component={projectSection} />
      <Redirect to={`${match.url}/`} />
    </Switch>
  </DashboardPage>
);

const AppRouter = () => (
  <Router history={history}>
    <Switch>
      <Route
        exact
        path="/"
        render={() => {
          const jwt = localStorage.getItem('jwt');

          if (jwt) {
            return <Redirect to="/dashboard" />;
          } else {
            localStorage.removeItem('jwt');
            return <Redirect to="/login" />;
          }
        }}
      />
      <Route exact path="/login" component={LoginPage} />
      <Route exact path="/qr-stickers/:qrStickerId" component={QRStickerPage} />
      <PrivateRoute path="/dashboard" component={dashboardSection} />
      <Redirect to="/" />
    </Switch>
  </Router>
);

export default AppRouter;
