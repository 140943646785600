import { getJWT } from '../utils/jwt';
import queryStringBuilder from '../utils/queryStringBuilder';

const API_URL = process.env.REACT_APP_API_URL;

export const getExternalSensors = (queries) =>
  fetch(`${API_URL}/external-sensors${queryStringBuilder(queries)}`, {
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${getJWT()}`,
    },
  });

export const getExternalSensor = (alertId) =>
  fetch(`${API_URL}/external-sensors/${alertId}`, {
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${getJWT()}`,
    },
  });

export const createExternalSensor = (alert) =>
  fetch(`${API_URL}/external-sensors`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${getJWT()}`,
    },
    body: JSON.stringify(alert),
  });

export const updateExternalSensor = (alertId, alert) =>
  fetch(`${API_URL}/external-sensors/${alertId}`, {
    method: 'PUT',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${getJWT()}`,
    },
    body: JSON.stringify(alert),
  });

export const deleteExternalSensor = (alertId) =>
  fetch(`${API_URL}/external-sensors/${alertId}`, {
    method: 'DELETE',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${getJWT()}`,
    },
  });

export const batchDeleteExternalSensors = (alertIds) =>
  fetch(`${API_URL}/external-sensors/batch-delete`, {
    method: 'DELETE',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${getJWT()}`,
    },
    body: JSON.stringify({ alertIds }),
  });
