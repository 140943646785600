import { COLLAPSE_SIDEBAR, UPDATE_DATAPOINTS } from '../actionTypes';

const INITIAL_STATE = {
  isSidebarCollapsed: window.innerWidth <= 480,
  datapoints: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case COLLAPSE_SIDEBAR:
      return {
        ...state,
        isSidebarCollapsed: action.payload,
      };
    case UPDATE_DATAPOINTS:
      return {
        ...state,
        datapoints: action.payload,
      };
    default:
      return state;
  }
};
