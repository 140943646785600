import React from 'react';
import { Button } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import moment from 'moment';
import _ from 'lodash';

import './style.scss';

const TicketCard = (props) => {
  const { zoneList, ticket, onUpdateClick } = props;
  const { createdAt, issuer, zoneId, ticketProcesses } = ticket;
  const zone = _.find(zoneList, { value: zoneId });

  return (
    <div className="TicketCard">
      <div className="ticket-icon">
        <UserOutlined />
      </div>
      <div className="ticket-date">{moment(createdAt).format('DD/MM/YY')}</div>
      <div className="ticket-user">{issuer ? issuer.name : 'Guest'}</div>
      <div className="ticket-zone">{zone ? zone.label : zoneId}</div>
      <div className="ticket-progress-list">
        {_.map(ticketProcesses, (step, i) => (
          <div className="ticket-progress" key={i}>
            <div className="progress-time">{moment(step.createdAt).format('DD/MM HH:mm')}</div>
            <div className="progress-message">{step.message}</div>
          </div>
        ))}
      </div>
      <div className="ticket-btn">
        <Button onClick={(e) => onUpdateClick(e)}>Update</Button>
      </div>
    </div>
  );
};

export default TicketCard;
