import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Layout, Switch, Table, Tooltip } from 'antd';
import { CheckOutlined, CloseOutlined, DownloadOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons';
import QRCode from 'qrcode';
import download from 'downloadjs';
import _ from 'lodash';

import * as qrStickerController from '../../../../../../api/qrSticker';
import IrreversiblePopconfirmWrapper from '../../../../../../components/IrreversiblePopconfirmWrapper';
import InfoButton from '../../../../../../components/InfoButton';
import TableButtonGroup from '../../../../../../components/TableButtonGroup';
import QRStickerFormModal from './components/QRStickerFormModal';
import './style.scss';

const QRStickerManagementPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedQRStickerId, setSelectedQRStickerId] = useState();
  const [isQRStickerFormModal, setIsQRStickerFormModal] = useState(false);
  const selectedProjectId = useSelector((state) => state.user.selectedProjectId);
  const projectData = useSelector((state) => state.user.projectData);
  const zoneList = _.flatMap(projectData, (floor) =>
    _.flatMap(floor.rooms, (room) => ({
      label: `${room.name}`,
      value: room.id,
      isVav: room.type === 'vav',
    }))
  );

  const getQRStickers = () => {
    if (selectedProjectId !== undefined) {
      setIsLoading(true);

      qrStickerController
        .getQRStickers({ project_id: selectedProjectId })
        .then((res) => res.json())
        .then((json) => {
          setDataSource(json);
          setIsLoading(false);
        });
    }
  };

  const updateQRSticker = (qrStickerId, newValue) => {
    setIsLoading(true);

    qrStickerController
      .updateQRSticker(qrStickerId, newValue)
      .then((res) => res.json())
      .then((json) => {
        getQRStickers();
      });
  };

  const deleteQRSticker = (qrStickerId) => {
    setIsLoading(true);

    qrStickerController
      .deleteQRSticker(qrStickerId)
      .then((res) => res.json())
      .then((json) => {
        getQRStickers();
        setIsLoading(false);
      });
  };

  const batchDeleteQRStickers = (qrStickerIds) => {
    setIsLoading(true);

    qrStickerController
      .batchDeleteQRStickers(qrStickerIds)
      .then((res) => res.json())
      .then((json) => {
        getQRStickers();
        setIsLoading(false);
      });
  };

  useEffect(() => getQRStickers(), [selectedProjectId]);

  const columns = [
    {
      title: 'Zone Display Name',
      dataIndex: 'name',
      key: 'name',
      align: 'left',
    },
    {
      title: 'Zone',
      dataIndex: 'zoneId',
      key: 'zoneId',
      align: 'left',
      render: (value, record) => {
        const targetZone = _.find(zoneList, { value });

        if (targetZone) {
          return targetZone.label;
        } else {
          return value;
        }
      },
    },
    {
      title: 'Active?',
      dataIndex: 'isActive',
      key: 'isActive',
      align: 'center',
      width: 100,
      render: (value, record) => (
        <Switch
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          checked={value}
          onChange={(value) => updateQRSticker(record.id, { isActive: value })}
        />
      ),
    },
    {
      title: 'Operation',
      dataIndex: '',
      align: 'center',
      width: 150,
      render: (value, record) => {
        const loc = window.location;

        return (
          <div className="operation-btn-gp">
            <Tooltip title="Preview">
              <SearchOutlined onClick={() => window.open(`${loc.protocol}//${loc.host}/qr-stickers/${record.id}`)} />
            </Tooltip>
            <Tooltip title="Download">
              <DownloadOutlined
                onClick={() =>
                  QRCode.toDataURL(`${loc.protocol}//${loc.host}/qr-stickers/${record.id}`, { width: 700 })
                    .then((url) => {
                      download(url, `${record.name}-qr-code.png`, 'image/png');
                    })
                    .catch((err) => {
                      console.error(err);
                    })
                }
              />
            </Tooltip>
            <Tooltip title="Edit">
              <EditOutlined
                onClick={() => {
                  setIsQRStickerFormModal(true);
                  setSelectedQRStickerId(record.id);
                }}
              />
            </Tooltip>
            <IrreversiblePopconfirmWrapper onConfirm={() => deleteQRSticker(record.id)}>
              <Tooltip title="Delete">
                <CloseOutlined className="delete-btn" />
              </Tooltip>
            </IrreversiblePopconfirmWrapper>
          </div>
        );
      },
    },
  ];

  return (
    <Layout>
      <div className="QRStickerManagementPage">
        <div className="main-section">
          <h2>
            QR Stickers{' '}
            <InfoButton title="People can request temperature change or submit CS ticket in specified zone via scanning the generated QR Sticker by QR Code Scanner." />
          </h2>
          <TableButtonGroup
            enabledButtons={['create', 'delete']}
            onCreate={() => setIsQRStickerFormModal(true)}
            onDelete={() => batchDeleteQRStickers(selectedRowKeys)}
          />
          <Table
            loading={isLoading}
            rowKey="id"
            columns={columns}
            dataSource={dataSource}
            rowSelection={{
              selectedRowKeys,
              onChange: (selectedRowKeys) => setSelectedRowKeys(selectedRowKeys),
            }}
            pagination={{
              showSizeChanger: false,
            }}
            scroll={{ x: 640 }}
          />
        </div>
      </div>
      <QRStickerFormModal
        selectedProjectId={selectedProjectId}
        zoneList={zoneList}
        selectedQRStickerId={selectedQRStickerId}
        visible={isQRStickerFormModal}
        onCancel={(e) => setIsQRStickerFormModal(false)}
        afterSubmit={() => {
          setIsQRStickerFormModal(false);
          setSelectedQRStickerId(undefined);
          getQRStickers();
        }}
      />
    </Layout>
  );
};

export default QRStickerManagementPage;
