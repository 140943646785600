import React, { useState, useEffect } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { DatePicker, Modal } from 'antd';
import ReactEcharts from 'echarts-for-react';
import echarts from 'echarts';
import _ from 'lodash';
import moment from 'moment';
// import randomColor from 'randomcolor';

import * as zoneController from '../../../../../../../../api/zone';
import './style.scss';

const { RangePicker } = DatePicker;

const ComfortZoneModal = (props) => {
  const { visible, selectedZone } = props;
  const [zoneData, setZoneData] = useState([]);
  const [range, setRange] = useState({
    from: moment().startOf('month'),
    to: moment().endOf('month'),
  });
  const { from, to } = range;
  const selectedZoneId = selectedZone.id;
  const selectedZoneName = selectedZone.name;

  const getZoneData = () => {
    zoneController
      .getZoneComfortProfile(selectedZoneId, { from: from.toISOString(), to: to.toISOString() })
      .then((res) => res.json())
      .then((json) => setZoneData(json.data));
  };

  const getOptions = () => {
    let series = [];
    _.forEach(zoneData, (userData) => {
      let coords = _.map(userData.boundary, (data) => [data.temp, data.humidity]);

      series.push({
        type: 'scatter',
        symbolSize: 2,
        itemStyle: {
          color: 'rgba(238,110,31, 1)',
        },
        data: _.map(userData.data, (data) => [data.temp, data.humidity]),
        animation: false,
        silent: true,
      });
      series.push({
        type: 'custom',
        renderItem: (params, api) => {
          let points = [];
          for (let i = 0; i < coords.length; i++) {
            points.push(api.coord(coords[i]));
          }

          return {
            type: 'polygon',
            shape: {
              points: echarts.graphic.clipPointsByRect(points, {
                x: params.coordSys.x,
                y: params.coordSys.y,
                width: params.coordSys.width,
                height: params.coordSys.height,
              }),
            },
            style: api.style({
              fill: 'rgba(238,110,31, 0.03)',
            }),
            styleEmphasis: api.style({
              fill: 'rgba(238,110,31, 0.5)',
            }),
          };
        },
        animation: false,
        data: coords,
      });
    });

    let options = {
      xAxis: {
        name: 'Temperature(°C)',
        nameLocation: 'middle',
        nameGap: 20,
        scale: true,
      },
      yAxis: {
        name: 'Humidity(%)',
        nameLocation: 'middle',
        nameGap: 50,
        scale: true,
      },
      series,
    };

    return options;
  };

  useDeepCompareEffect(() => {
    if (visible) {
      getZoneData();
    } else {
      setZoneData([]);
    }
  }, [visible, range]);

  return (
    <Modal className="ComfortZoneModal" width="70%" title={null} footer={null} {...props}>
      <h2>Comfort Profile Distribution of {selectedZoneName}</h2>
      <div className="date-picker">
        <RangePicker
          placeholder={['From', 'To']}
          allowClear={false}
          value={[from, to]}
          onChange={(dates) => setRange({ from: dates[0], to: dates[1] })}
        />
      </div>
      <ReactEcharts style={{ height: '500px' }} notMerge option={getOptions()} />
    </Modal>
  );
};

export default ComfortZoneModal;
