import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Layout, Table, Tooltip } from 'antd';
import { CloseOutlined, EditOutlined } from '@ant-design/icons';
import moment from 'moment';
import _ from 'lodash';

import * as externalSensorController from '../../../../../../api/externalSensor';
import IrreversiblePopconfirmWrapper from '../../../../../../components/IrreversiblePopconfirmWrapper';
import InfoButton from '../../../../../../components/InfoButton';
import TableButtonGroup from '../../../../../../components/TableButtonGroup';
import ExternalSensorFormModal from './components/ExternalSensorFormModal';
import './style.scss';

const ExternalSensorManagementPage = () => {
  const [dataSource, setDataSource] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedExternalSensorId, setSelectedExternalSensorId] = useState();
  const [isExternalSensorFormModal, setIsExternalSensorFormModal] = useState(false);
  const belimoUserId = useSelector((state) => state.user.belimoUserId);
  const selectedProjectId = useSelector((state) => state.user.selectedProjectId);
  const projectData = useSelector((state) => state.user.projectData);
  const zoneList = _.flatMap(projectData, (building) =>
    _.flatMap(building.floors, (floor) =>
      _.flatMap(floor.rooms, (room) => ({
        label: `${building.name}/${floor.name}/${room.name}`,
        value: room.id,
      }))
    )
  );

  const getExternalSensors = () => {
    if (selectedProjectId !== undefined) {
      externalSensorController
        .getExternalSensors({ project_id: selectedProjectId })
        .then((res) => res.json())
        .then((json) => {
          setDataSource(json);
        });
    }
  };

  const deleteExternalSensor = (externalSensorId) => {
    externalSensorController
      .deleteExternalSensor(externalSensorId)
      .then((res) => res.json())
      .then((json) => {
        getExternalSensors();
      });
  };

  const batchDeleteExternalSensors = (externalSensorIds) => {
    externalSensorController
      .batchDeleteExternalSensors(externalSensorIds)
      .then((res) => res.json())
      .then((json) => {
        getExternalSensors();
      });
  };

  useEffect(() => getExternalSensors(), [selectedProjectId]);

  const columns = [
    {
      title: 'Sensor Name',
      dataIndex: 'name',
      key: 'name',
      align: 'left',
    },
    {
      title: 'Zone',
      dataIndex: 'zoneId',
      key: 'zoneId',
      render: (value, record) => {
        const targetZone = _.find(zoneList, { value });

        if (targetZone) {
          return targetZone.label;
        } else {
          return value;
        }
      },
    },
    {
      title: 'Topic',
      dataIndex: 'topic',
      key: 'topic',
    },
    {
      title: 'Used for AI control?',
      dataIndex: 'isControlled',
      key: 'isControlled',
      align: 'center',
      width: 200,
      render: (value, record) => (value ? 'Yes' : 'No'),
    },
    {
      title: 'Operation',
      dataIndex: '',
      align: 'center',
      width: 100,
      render: (value, record) => {
        const loc = window.location;

        return (
          <div className="operation-btn-gp">
            <Tooltip title="Edit">
              <EditOutlined
                onClick={() => {
                  setIsExternalSensorFormModal(true);
                  setSelectedExternalSensorId(record.id);
                }}
              />
            </Tooltip>
            <IrreversiblePopconfirmWrapper onConfirm={() => deleteExternalSensor(record.id)}>
              <Tooltip title="Delete">
                <CloseOutlined className="delete-btn" />
              </Tooltip>
            </IrreversiblePopconfirmWrapper>
          </div>
        );
      },
    },
  ];

  return (
    <Layout>
      <div className="ExternalSensorManagementPage">
        <div className="main-section">
          <h2>
            External Sensors{' '}
            {/* <InfoButton title="You can add the external se" /> */}
          </h2>
          <TableButtonGroup
            enabledButtons={['create', 'delete']}
            onCreate={() => setIsExternalSensorFormModal(true)}
            onDelete={() => batchDeleteExternalSensors(selectedRowKeys)}
          />
          <Table
            rowKey="id"
            columns={columns}
            dataSource={dataSource}
            rowSelection={{
              selectedRowKeys,
              onChange: (selectedRowKeys) => setSelectedRowKeys(selectedRowKeys),
            }}
            pagination={{
              showSizeChanger: false,
            }}
            scroll={{ x: 640 }}
          />
        </div>
      </div>
      <ExternalSensorFormModal
        belimoUserId={belimoUserId}
        selectedProjectId={selectedProjectId}
        zoneList={zoneList}
        selectedExternalSensorId={selectedExternalSensorId}
        visible={isExternalSensorFormModal}
        onCancel={(e) => {
          setIsExternalSensorFormModal(false);
          setSelectedExternalSensorId(undefined);
        }}
        afterSubmit={() => {
          setSelectedExternalSensorId(undefined);
          getExternalSensors();
        }}
      />
    </Layout>
  );
};

export default ExternalSensorManagementPage;
