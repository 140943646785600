import React, { useState, useEffect } from 'react';
import { Col, message, Row, Spin, Statistic } from 'antd';
import numeral from 'numeral';
import _ from 'lodash';
import { HistoryOutlined } from '@ant-design/icons';

import * as guestApi from '../../api/guest';
import RequestHistoryModal from './scenes/RequestHistoryModal';
import RequestTempChangeModal from './scenes/RequestTempChangeModal';
import SubmitCSTicketModal from './scenes/SubmitCSTicketModal';
import logo from './logo_hkstp_color.svg';
// import logo from './logo.png';
import './style.scss';

const QRStickerPage = (props) => {
  const { match } = props;
  const [qrSticker, setQRSticker] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isRequestHistoryModalVisible, setIsRequestHistoryModalVisible] = useState(false);
  const [isRequestTempChangeModalVisible, setIsRequestTempChangeModalVisible] = useState(false);
  const [isSubmitCSTicketModalVisible, setIsSubmitCSTicketModalVisible] = useState(false);
  const { qrStickerId } = match.params;

  useEffect(() => {
    let ignore = false;
    const getQRSticker = (qrStickerId) => {
      setIsLoading(true);
      guestApi
        .getQRSticker(qrStickerId)
        .then((res) => res.json())
        .then((json) => {
          if (!ignore) {
            if (!json.error) {
              setQRSticker(json);
            } else {
              setErrorMessage(json.error.message);
            }
            setIsLoading(false);
          }
        });
    };

    getQRSticker(qrStickerId);
  }, [qrStickerId]);

  const renderMenu = () => {
    return (
      <div className="btn-container">
        <div className="history-btn" onClick={() => setIsRequestHistoryModalVisible(true)}>
          <HistoryOutlined />
        </div>
        <img className="logo" src={logo} alt="Belimo Logo" />
        <div className="outdoor-info">
          <Row>
            <Col span={24}>
              <p>Outdoor Weather:</p>
              <h1>
                {numeral(qrSticker.weather.temp).format('0.[00]')} °C
                <br />
                {_.startCase(qrSticker.weather.description)}
              </h1>
            </Col>
          </Row>
        </div>
        <p>You are now in:</p>
        <h1>{qrSticker.zoneName}</h1>
        <p>Zone Temperature:</p>
        <h1>{qrSticker.zoneTemp ? `${numeral(qrSticker.zoneTemp).format('0.[00]')} °C` : 'N/A'}</h1>
        {qrSticker.isVav && (
          <div className="belimo-btn" onClick={() => setIsRequestTempChangeModalVisible(true)}>
            <div className="verb">Request</div>
            <div>Temperature Change</div>
          </div>
        )}
        <div className="belimo-btn" onClick={() => setIsSubmitCSTicketModalVisible(true)}>
          <div className="verb">Submit</div>
          <div>Customer Service Ticket</div>
        </div>
        <div className="credit">
          Seasons AI - Powered by <a href="https://futureimpactlab.com">Future Impact Lab</a>
        </div>
      </div>
    );
  };

  const renderErrorMessage = () => {
    return (
      <div className="btn-container">
        <img className="logo" src={logo} alt="Belimo Logo" />
        <h1>{errorMessage}</h1>
      </div>
    );
  };

  return (
    <Spin spinning={isLoading} tip="Verifying...">
      <div className="QRStickerPage">{!_.isEmpty(qrSticker) ? renderMenu() : renderErrorMessage()}</div>
      <RequestHistoryModal
        projectId={qrSticker.projectId}
        zoneId={qrSticker.zoneId}
        visible={isRequestHistoryModalVisible}
        onCancel={() => setIsRequestHistoryModalVisible(false)}
      />
      <RequestTempChangeModal
        projectId={qrSticker.projectId}
        zoneId={qrSticker.zoneId}
        currentTemp={qrSticker.zoneTemp}
        visible={isRequestTempChangeModalVisible}
        onCancel={() => setIsRequestTempChangeModalVisible(false)}
        afterSubmit={() => {
          message.success('Request submitted.');
          setIsRequestTempChangeModalVisible(false);
        }}
      />
      <SubmitCSTicketModal
        projectId={qrSticker.projectId}
        zoneId={qrSticker.zoneId}
        visible={isSubmitCSTicketModalVisible}
        onCancel={() => setIsSubmitCSTicketModalVisible(false)}
        afterSubmit={() => {
          message.success('Ticket submitted.');
          setIsSubmitCSTicketModalVisible(false);
        }}
      />
    </Spin>
  );
};

export default QRStickerPage;
