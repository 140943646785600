import React, { useState, useEffect } from 'react';
import { Button, Form, Input, InputNumber, Modal, Select, Spin } from 'antd';

import * as guestApi from '../../../../api/guest';
import { setTicketHistory } from '../../../../utils/localProfile';
import './style.scss';
import _ from 'lodash';

const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;

const SubmitCSTicketModal = (props) => {
  const { projectId, zoneId, afterSubmit, visible } = props;
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [requestTypes, setRequestTypes] = useState([]);

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 24 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
    },
  };

  const handleSubmit = (values) => {
    setIsLoading(true);

    guestApi
      .createTicket({ projectId, zoneId, ...values })
      .then((result) => result.json())
      .then((json) => {
        let newTickets = JSON.parse(localStorage.getItem('tickets')) || {};
        if (newTickets[zoneId]) {
          newTickets[zoneId].push(json.id);
        } else {
          newTickets[zoneId] = [json.id];
        }
        setTicketHistory(newTickets);

        setIsLoading(false);
        afterSubmit();
      });
  };

  useEffect(() => {
    guestApi
      .getRequestTypes()
      .then((result) => result.json())
      .then((json) => {
        setRequestTypes(json);
      });
  }, [visible]);

  return (
    <Modal
      centered
      forceRender
      className="FormModal SubmitCSTicketModal"
      width="60%"
      style={{ pointerEvents: isLoading ? 'none' : '' }}
      maskClosable={!isLoading}
      title={null}
      footer={null}
      {...props}
    >
      <Spin spinning={isLoading} tip="Loading...">
        <Form form={form} initialValues={{ isActive: true }} onFinish={(values) => handleSubmit(values)}>
          <FormItem
            {...formItemLayout}
            label="Involved People"
            name="involvedPeople"
            labelAlign="left"
            rules={[{ required: true, message: 'This field is required.' }]}
          >
            <InputNumber min={1} />
          </FormItem>
          <FormItem
            {...formItemLayout}
            label="Request Type"
            name="requestType"
            labelAlign="left"
            rules={[{ required: true, message: 'This field is required.' }]}
          >
            <Select>
              {_.map(requestTypes, (requestType) => (
                <Option key={requestType.value} value={requestType.value}>
                  {requestType.label}
                </Option>
              ))}
            </Select>
          </FormItem>
          <FormItem
            {...formItemLayout}
            label="Message"
            name="message"
            labelAlign="left"
            rules={[{ required: true, message: 'This field is required.' }]}
          >
            <TextArea autoSize={{ minRows: 4, maxRows: 4 }} />
          </FormItem>
          <FormItem
            {...formItemLayout}
            label="Contact Number"
            name="contactNumber"
            labelAlign="left"
            rules={[{ required: true, message: 'This field is required.' }]}
          >
            <Input />
          </FormItem>
          <FormItem>
            <Button type="primary" size="large" htmlType="submit">
              Submit
            </Button>
          </FormItem>
        </Form>
      </Spin>
    </Modal>
  );
};

export default SubmitCSTicketModal;
