import { getJWT } from '../utils/jwt';
import queryStringBuilder from '../utils/queryStringBuilder';

const API_URL = process.env.REACT_APP_API_URL;

export const getQRStickers = (queries) =>
  fetch(`${API_URL}/qr-stickers${queryStringBuilder(queries)}`, {
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${getJWT()}`,
    },
  });

export const getQRSticker = (qrStickerId) =>
  fetch(`${API_URL}/qr-stickers/${qrStickerId}`, {
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${getJWT()}`,
    },
  });

export const createQRSticker = (qrSticker) =>
  fetch(`${API_URL}/qr-stickers`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${getJWT()}`,
    },
    body: JSON.stringify(qrSticker),
  });

export const updateQRSticker = (qrStickerId, qrSticker) =>
  fetch(`${API_URL}/qr-stickers/${qrStickerId}`, {
    method: 'PUT',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${getJWT()}`,
    },
    body: JSON.stringify(qrSticker),
  });

export const deleteQRSticker = (qrStickerId) =>
  fetch(`${API_URL}/qr-stickers/${qrStickerId}`, {
    method: 'DELETE',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${getJWT()}`,
    },
  });

export const batchDeleteQRStickers = (qrStickerIds) =>
  fetch(`${API_URL}/qr-stickers/batch-delete`, {
    method: 'DELETE',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${getJWT()}`,
    },
    body: JSON.stringify({ qrStickerIds }),
  });
