import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Tabs, Table, Tooltip } from 'antd';
import { SolutionOutlined } from '@ant-design/icons';
import moment from 'moment';
import _ from 'lodash';

// import { getWSUrl } from '../../../../../../utils/ws';
import * as ticketController from '../../../../../../api/ticket';
import IrreversiblePopconfirmWrapper from '../../../../../../components/IrreversiblePopconfirmWrapper';
import TicketCard from './components/TicketCard';
import TicketCardModal from './components/TicketCardModal';
import './style.scss';

const TabPane = Tabs.TabPane;

// const WS_URL = getWSUrl();

const TicketsPage = (props) => {
  const userId = useSelector((state) => state.user.id);
  const selectedProjectId = useSelector((state) => state.user.selectedProjectId);
  const projectData = useSelector((state) => state.user.projectData);
  const [dataSource, setDataSource] = useState([]);
  const [assignedTickets, setAssignedTickets] = useState([]);
  const [selectedTicketId, setSelectedTicketId] = useState();
  const [isTicketModalVisible, setIsTicketModalVisible] = useState(false);
  const zoneList = _.flatMap(projectData, (floor) =>
    _.flatMap(floor.rooms, (room) => ({
      label: `${room.name}`,
      value: room.id,
    }))
  );

  const getTickets = () => {
    ticketController
      .getTickets({ project_id: selectedProjectId })
      .then((res) => res.json())
      .then((json) => {
        setDataSource(json);
        setAssignedTickets(_.filter(json, (ticket) => ticket.handlerId === parseInt(userId) && !ticket.isCompleted));
      });
  };

  const handleFollowUpRequestClick = (ticketId) => {
    ticketController
      .followUpTicket(ticketId)
      .then((res) => res.json())
      .then((json) => getTickets());
  };

  const afterMessageUpdate = () => {
    setIsTicketModalVisible(false);
    getTickets();
  };

  // useEffect(() => {
  //   const ws = new WebSocket(WS_URL);
  //   ws.addEventListener('message', (event) => {
  //     const json = JSON.parse(event.data);
  //     if (json.type === 'newTicketIn') getTickets();
  //   });
  // }, []);
  useEffect(() => {
    if (selectedProjectId) {
      getTickets();
    }
  }, [selectedProjectId]);

  const columns = [
    {
      title: 'Request at',
      dataIndex: 'createdAt',
      align: 'center',
      render: (value, record) => (
        <div>
          <div>{moment(value).format('DD/MM/YYYY')}</div>
          <div>{moment(value).format('HH:mm')}</div>
        </div>
      ),
    },
    {
      title: 'Zone',
      dataIndex: 'zoneId',
      align: 'center',
      render: (value, record) => {
        const targetZone = _.find(zoneList, { value });

        if (targetZone) {
          return targetZone.label;
        } else {
          return value;
        }
      },
    },
    {
      title: 'Issuer',
      dataIndex: ['issuer', 'name'],
      align: 'center',
      render: (value, record) => (record.contactNumber ? `${value || 'Guest'} (${record.contactNumber})` : value),
    },
    {
      title: 'Involved Ppl.',
      dataIndex: 'involvedPeople',
      align: 'center',
    },
    {
      title: 'Request Type',
      dataIndex: 'requestType',
      align: 'center',
    },
    {
      title: 'Latest Progress',
      dataIndex: 'ticketProcesses',
      render: (ticketProcesses, record) => (
        <div>
          <div>{ticketProcesses[0].message}</div>
          <div className="ticket-process-submessage">
            Last Updated: {moment(ticketProcesses[0].createdAt).format('DD/MM/YYYY HH:mm')}
          </div>
        </div>
      ),
    },
  ];
  const unhandledColumns = _.concat(columns, {
      title: 'Follow Up',
      align: 'center',
      render: (value, record) => {
        if (_.isNull(record.handler))
          return (
            <IrreversiblePopconfirmWrapper
              arrowPointAtCenter={true}
              placement="topRight"
              onConfirm={() => handleFollowUpRequestClick(record.id)}
            >
              <Tooltip arrowPointAtCenter={true} placement="bottomRight" title="Follow up this request">
                <SolutionOutlined className="handle-btn" />
              </Tooltip>
            </IrreversiblePopconfirmWrapper>
          );
      },
    }),
    handledColumns = _.concat(columns, {
      title: 'Handled By',
      dataIndex: ['handler', 'name'],
      align: 'center',
    });

  return (
    <div className="TicketsPage">
      <div className="ticket-in-charge-section">
        <div className="page-header">
          <h2>Tickets in Charge</h2>
        </div>
        <div className="ticket-card-list">
          {assignedTickets.length !== 0 ? (
            _.map(assignedTickets, (ticket, i) => (
              <TicketCard
                key={i}
                zoneList={zoneList}
                ticket={ticket}
                onUpdateClick={(e) => {
                  setSelectedTicketId(ticket.id);
                  setIsTicketModalVisible(true);
                }}
              />
            ))
          ) : (
            <p className="instruction">No tickets yet.</p>
          )}
        </div>
      </div>
      <div className="ticket-section">
        <div className="page-header">
          <h2>All Tickets</h2>
        </div>
        <div className="ticket-table-list">
          <Tabs defaultActiveKey="Unhandled" type="card">
            <TabPane tab="Unhandled" key="Unhandled">
              <Table
                size="middle"
                columns={unhandledColumns}
                dataSource={_.filter(dataSource, (record) => _.isNull(record.handler))}
                rowKey="id"
                pagination={{
                  pageSize: 5,
                  showSizeChanger: false
                }}
                scroll={{ x: 640 }}
              />
            </TabPane>
            <TabPane tab="Processing" key="Processing">
              <Table
                size="middle"
                columns={handledColumns}
                dataSource={_.filter(dataSource, (record) => !_.isNull(record.handler) && !record.isCompleted)}
                rowKey="id"
                pagination={{
                  pageSize: 5,
                  showSizeChanger: false
                }}
                scroll={{ x: 640 }}
              />
            </TabPane>
            <TabPane tab="Completed" key="Completed">
              <Table
                size="middle"
                columns={handledColumns}
                dataSource={_.filter(dataSource, (record) => record.isCompleted)}
                rowKey="id"
                pagination={{
                  pageSize: 5,
                  showSizeChanger: false
                }}
                scroll={{ x: 640 }}
              />
            </TabPane>
          </Tabs>
        </div>
      </div>
      <TicketCardModal
        zoneList={zoneList}
        selectedTicketId={selectedTicketId}
        visible={isTicketModalVisible}
        onCancel={(e) => setIsTicketModalVisible(false)}
        afterSubmit={() => afterMessageUpdate()}
      />
    </div>
  );
};

export default TicketsPage;
