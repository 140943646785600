import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { NavLink } from 'react-router-dom';
import { Table, Tabs, Tooltip } from 'antd';
import { DotChartOutlined, HistoryOutlined, InfoCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import numeral from 'numeral';
import _ from 'lodash';

import { getWSUrl } from '../../../../../../utils/ws';
import { getJWT } from '../../../../../../utils/jwt';
import InfoButton from '../../../../../../components/InfoButton';
import ComfortZoneModal from './components/ComfortZoneModal';
import * as zoneApi from '../../../../../../api/zone';

import './style.scss';

const { TabPane } = Tabs;

const WS_URL = getWSUrl();

const SummaryPage = (props) => {
  const { history } = props;
  const selectedProjectId = useSelector((state) => state.user.selectedProjectId);
  const projectData = useSelector((state) => state.user.projectData);
  const isLoadingProjectData = useSelector((state) => state.user.isLoadingProjectData);
  const [data, setData] = useState([]);
  const [selectedZone, setSelectedZone] = useState({});
  const [isComfortZoneModalVisible, setIsComfortZoneModalVisible] = useState(false);

  const zoneDetailList = _.flatMap(projectData, (floor) =>
    _.flatMap(floor.rooms, (room) => ({
      label: `${room.name}`,
      value: room.id,
      isVav: room.type === 'vav',
    }))
  );
  const zoneList = _.map(zoneDetailList, (zone) => zone.value);
  console.log(zoneList);

  const getZoneStat = (zoneIds) => {
    console.log(data);
    zoneApi
      .getZoneStat({ zone_ids: zoneIds })
      .then((res) => res.json())
      .then((json) => {
        setData((prevData) =>
          _.flatMap(prevData, (room) => {
            const targetZoneStat = _.find(json, { zoneId: room.id });
            if (targetZoneStat)
              return {
                ...room,
                userDistribution: targetZoneStat.userDistribution,
                pendingZoneRequest: targetZoneStat.pendingZoneRequest,
                successZoneRequest: targetZoneStat.successZoneRequest,
                tickets: targetZoneStat.tickets,
              };
            else return room;
          })
        );
      });
  };

  const updateData = (newDeviceState) => {
    setData((prevData) =>
      _.map(prevData, (room) => {
        return {
          ...room,
          devices: _.map(room.devices, (device) => {
            let deviceData = device;

            if (device.cloudId === newDeviceState.deviceId) {
              deviceData = {
                ...deviceData,
                ...newDeviceState.datapoints,
              };
            }

            return deviceData;
          }),
        };
      })
    );
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      align: 'left',
      fixed: 'left',
      width: 150,
      render: (text, record) => <div className="child-name">{text}</div>,
    },
    {
      title: 'Avg. Temp (°C)',
      dataIndex: 'devices',
      align: 'center',
      render: (devices, record) => {
        if (record.isRoomSlot) {
          const validDevices = _.filter(devices, 'D45EuDN7.66');
          return validDevices.length > 0
            ? numeral(_.meanBy(validDevices, (device) => device['D45EuDN7.66'])).format('0.[00]')
            : '-';
        }
      },
    },
    {
      title: (
        <div>
          Target Setpoint (°C) <InfoButton title="This is showing the pending setpoint request now." />
        </div>
      ),
      dataIndex: 'pendingZoneRequest',
      align: 'center',
      render: (zoneRequest, record) => {
        if (record.isRoomSlot && record.isVav) {
          if (zoneRequest) {
            return (
              <div>
                <div>{numeral(parseFloat(zoneRequest.targetTemp)).format('0.[00]')}</div>
                <div className="zone-request-submessage">
                  Last Updated: {moment(zoneRequest.updatedAt).format('HH:mm')}
                </div>
              </div>
            );
          } else {
            return '-';
          }
        } else {
          return '';
        }
      },
    },
    {
      title: (
        <div>
          Accepted Setpoint (°C){' '}
          <InfoButton title="This is showing the last setpoint that Belimo Cloud accepted within today." />
        </div>
      ),
      dataIndex: 'successZoneRequest',
      align: 'center',
      render: (zoneRequest, record) => {
        if (record.isRoomSlot && record.isVav) {
          if (zoneRequest) {
            return (
              <div>
                <div>{numeral(parseFloat(zoneRequest.targetTemp)).format('0.[00]')}</div>
                <div className="zone-request-submessage">
                  Last Updated: {moment(zoneRequest.updatedAt).format('HH:mm')}
                </div>{' '}
                {/* <HistoryOutlined /> */}
              </div>
            );
          } else {
            return (
              <div>
                -{' '}
                {/* <Tooltip title="Setpoint History">
                  <HistoryOutlined className="history-table-btn" />
                </Tooltip> */}
              </div>
            );
          }
        } else {
          return '';
        }
      },
    },
    {
      title: (
        <div>
          User Feelings{' '}
          <InfoButton title="This is showing the user distribution(COLD / NORMAL / HOT) in the past hour." />
          <br />
          <div className="user-feelings-title">
            <span className="cold">COLD</span>
            <span className="normal">NORMAL</span>
            <span className="hot">HOT</span>
          </div>
        </div>
      ),
      dataIndex: 'userDistribution',
      align: 'center',
      render: (userDistribution, record) => {
        if (record.isRoomSlot && record.isVav) {
          const groupedDistribution = _.groupBy(userDistribution, 'status');

          return (
            <div className="user-distribution">
              <span className="cold">{groupedDistribution['COLD'] ? groupedDistribution['COLD'].length : 0}</span>
              <span className="normal">{groupedDistribution['NORMAL'] ? groupedDistribution['NORMAL'].length : 0}</span>
              <span className="hot">{groupedDistribution['HOT'] ? groupedDistribution['HOT'].length : 0}</span>
            </div>
          );
        } else {
          return '';
        }
      },
    },
    {
      title: (
        <div>
          Active Tickets <InfoButton title="This is showing the number of unhandled/processing tickets." />
        </div>
      ),
      dataIndex: 'tickets',
      align: 'center',
      render: (value, record) => {
        if (record.isRoomSlot) {
          if (value > 0) {
            return <NavLink to="./tickets">{value}</NavLink>;
          } else {
            return 0;
          }
        }
      },
    },
    {
      title: 'Comfort Zone',
      dataIndex: 'comfortZone',
      align: 'center',
      render: (value, record) => {
        if (record.isRoomSlot && record.isVav) {
          return (
            <DotChartOutlined
              className="comfort-zone-btn"
              onClick={(e) => {
                setSelectedZone({ id: record.id, name: record.name });
                setIsComfortZoneModalVisible(true);
              }}
            />
          );
        } else {
          return '';
        }
      },
    },
  ];

  useDeepCompareEffect(() => {
    if (selectedProjectId) {
      // const ws = new WebSocket(`${WS_URL}?token=${getJWT()}&project_id=${selectedProjectId}`);
      // ws.addEventListener('message', (event) => {
      //   let json = {};
      //   try {
      //     json = JSON.parse(event.data);
      //     if (json.type === 'ZoneUpdate') {
      //       getZoneStat(zoneList);
      //     } else if (json.type === 'DeviceUpdate') {
      //       updateData(json);
      //     }
      //   } catch (error) {}
      // });

      setData(
        _.flatMap(projectData, (floor) => {
          return _.map(floor.rooms, (room) => {
            return {
              isRoomSlot: true,
              isVav: room.type === 'vav',
              id: room.id,
              name: room.name,
              devices: _.map(room.devices, (device) => ({
                id: device.id,
                name: device.name,
                cloudId: device.cloudId,
              })),
            };
          });
        })
      );

      if (zoneList.length > 0) {
        getZoneStat(zoneList);
      }

      // return () => {
      //   ws.close();
      // };
    }
  }, [projectData]);

  return (
    <div className="SummaryPage">
      <div className="main-section">
        <h2>Summary</h2>
        <Tabs defaultActiveKey="floor-plan">
          <TabPane tab="Floor Plan" key="floor-plan">
            <div className="floor-plan">
              {_.map(zoneDetailList, (zone) => {
                const zoneData = _.find(data, { zoneId: zone.value }) || {};

                return (
                  <div key={zone.value} id={_.kebabCase(zone.label)} className="zone-block">
                    {zone.label}
                    <br />
                    {zoneData.devices ? zoneData.devices.length : 'N/A'}
                  </div>
                );
              })}
            </div>
          </TabPane>
          <TabPane tab="Table" key="table">
            <Table
              rowKey={'id'}
              columns={columns}
              size="small"
              dataSource={data}
              scroll={{ x: 640, y: '65vh' }}
              pagination={false}
              loading={isLoadingProjectData}
            />
          </TabPane>
        </Tabs>
      </div>
      <ComfortZoneModal
        selectedZone={selectedZone}
        visible={isComfortZoneModalVisible}
        onCancel={() => {
          setSelectedZone({});
          setIsComfortZoneModalVisible(false);
        }}
      />
    </div>
  );
};

export default SummaryPage;
