import { COLLAPSE_SIDEBAR, UPDATE_DATAPOINTS } from '../actionTypes';

export const collapseSidebar = (isCollapsed) => ({
  type: COLLAPSE_SIDEBAR,
  payload: isCollapsed,
});

export const updateDatapoints = (datapoints) => ({
  type: UPDATE_DATAPOINTS,
  payload: datapoints,
});
