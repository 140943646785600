import React, { useState, useEffect } from 'react';
import { Button, Form, Input, Modal, Spin } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import _ from 'lodash';

import * as userController from '../../../../../../../../api/user';
import './style.scss';

const FormItem = Form.Item;

const ParameterListFormModal = (props) => {
  const { selectedProjectId, selectedParameterList, selectedParameters, formMode, visible, afterSubmit } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();

  const handleSubmit = (values) => {
    const extractedParameters = _.map(selectedParameters, (parameter) => {
      const { title, ...extractedParameter } = parameter;
      return extractedParameter;
    });
    setIsLoading(true);

    (formMode === 'UPDATE'
      ? userController.updateParameterList(selectedParameterList.id, {
          name: values.name,
          parameters: extractedParameters,
        })
      : userController.createParameterList({
          name: values.name,
          projectId: selectedProjectId,
          parameters: extractedParameters,
        })
    )
      .then((res) => res.json())
      .then((json) => {
        setIsLoading(false);
        afterSubmit();
      });
  };

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 12 },
    },
  };

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({ name: selectedParameterList.name });
    } else {
      form.resetFields();
    }
  }, [visible]);

  return (
    <Modal
      centered
      forceRender
      className="FormModal ParameterListFormModal"
      width="60%"
      style={{ pointerEvents: isLoading ? 'none' : '' }}
      maskClosable={!isLoading}
      title={null}
      footer={null}
      {...props}
    >
      <Spin spinning={isLoading} tip="Loading...">
        <Form form={form} onFinish={(values) => handleSubmit(values)}>
          <FormItem
            {...formItemLayout}
            label="Config Name"
            name="name"
            rules={[{ required: true, message: 'This field is required.' }]}
          >
            <Input />
          </FormItem>
          {formMode === 'UPDATE' ? (
            <FormItem
              labelCol={{
                xs: { span: 24 },
                sm: { span: 8 },
              }}
              wrapperCol={{
                xs: { span: 24 },
                sm: { span: 12 },
              }}
              label="Series Change"
            >
              <div className="series-list">
                {selectedParameterList.parameters && selectedParameterList.parameters.length > 0 ? (
                  _.map(selectedParameterList.parameters, (parameter, i) => (
                    <div key={`parameter-${i}`} className="series-name">
                      {parameter.title}
                    </div>
                  ))
                ) : (
                  <p className="series-message">No series selected</p>
                )}
              </div>
              <div className="series-icon-container">
                <DownOutlined />
              </div>
              <div className="series-list">
                {selectedParameters.length > 0 ? (
                  _.map(selectedParameters, (parameter, i) => (
                    <div key={`parameter-${i}`} className="series-name">
                      {parameter.title}
                    </div>
                  ))
                ) : (
                  <p className="series-message">No series selected</p>
                )}
              </div>
            </FormItem>
          ) : (
            <FormItem {...formItemLayout} label="Series">
              <div className="series-list">
                {selectedParameters.length > 0 ? (
                  _.map(selectedParameters, (parameter, i) => (
                    <div key={`parameter-${i}`} className="series-name">
                      {parameter.title}
                    </div>
                  ))
                ) : (
                  <p className="series-message">No series selected</p>
                )}
              </div>
            </FormItem>
          )}
          <FormItem>
            <Button type="primary" size="large" htmlType="submit">
              {formMode === 'UPDATE' ? 'Update' : 'Submit'}
            </Button>
          </FormItem>
        </Form>
      </Spin>
    </Modal>
  );
};

export default ParameterListFormModal;
