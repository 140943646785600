import React, { useState, useEffect } from 'react';
import { Button, Form, Input, Modal, Select, Spin, Switch } from 'antd';
import _ from 'lodash';

import * as qrStickerController from '../../../../../../../../api/qrSticker';

const FormItem = Form.Item;
const { Option } = Select;

const QRStickerFormModal = (props) => {
  const { visible, selectedProjectId, zoneList, selectedQRStickerId, afterSubmit } = props;
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  const getQRSticker = (qrStickerId) => {
    setIsLoading(true);
    qrStickerController
      .getQRSticker(qrStickerId)
      .then((res) => res.json())
      .then((json) => {
        form.setFieldsValue({ name: json.name, zoneId: json.zoneId, isActive: json.isActive });
        setIsLoading(false);
      });
  };
  const handleSubmit = (values) => {
    const targetZone = _.find(zoneList, { value: values.zoneId });
    setIsLoading(true);

    (selectedQRStickerId
      ? qrStickerController.updateQRSticker(selectedQRStickerId, { isVav: targetZone.isVav, ...values })
      : qrStickerController.createQRSticker({ projectId: selectedProjectId, isVav: targetZone.isVav, ...values })
    )
      .then((res) => res.json())
      .then((json) => {
        setIsLoading(false);
        afterSubmit();
      });
  };

  useEffect(() => {
    if (visible) {
      if (selectedQRStickerId !== undefined) {
        getQRSticker(selectedQRStickerId);
      } else {
        form.resetFields();
      }
    } else {
      form.resetFields();
    }
  }, [visible]);

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 12 },
    },
  };

  return (
    <Modal
      centered
      forceRender
      className="FormModal"
      width="60%"
      style={{ pointerEvents: isLoading ? 'none' : '' }}
      maskClosable={!isLoading}
      title={null}
      footer={null}
      {...props}
    >
      <Spin spinning={isLoading} tip="Loading...">
        <Form form={form} initialValues={{ isActive: true }} onFinish={(values) => handleSubmit(values)}>
          <FormItem
            {...formItemLayout}
            label="Zone Display Name"
            name="name"
            rules={[{ required: true, message: 'This field is required.' }]}
          >
            <Input />
          </FormItem>
          <FormItem
            {...formItemLayout}
            label="Zone"
            name="zoneId"
            rules={[{ required: true, message: 'This field is required.' }]}
          >
            <Select>
              {_.map(zoneList, (zone) => (
                <Option key={zone.value} value={zone.value}>
                  {zone.label}
                </Option>
              ))}
            </Select>
          </FormItem>
          <FormItem
            {...formItemLayout}
            label="Active?"
            name="isActive"
            valuePropName="checked"
            rules={[{ required: true, message: 'This field is required.' }]}
          >
            <Switch />
          </FormItem>
          <FormItem>
            <Button type="primary" size="large" htmlType="submit">
              Submit
            </Button>
          </FormItem>
        </Form>
      </Spin>
    </Modal>
  );
};

export default QRStickerFormModal;
