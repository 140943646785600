import React, { useState, useEffect } from 'react';
import { Layout, Table, Tooltip } from 'antd';
import { CloseOutlined, EditOutlined } from '@ant-design/icons';

import * as userController from '../../../../api/user';
import IrreversiblePopconfirmWrapper from '../../../../components/IrreversiblePopconfirmWrapper';
import InfoButton from '../../../../components/InfoButton';
import TableButtonGroup from '../../../../components/TableButtonGroup';
import SubordinateFormModal from './components/SubordinateFormModal';
import './style.scss';

const SubordinateManagementPage = () => {
  const [dataSource, setDataSource] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedSubordinateId, setSelectedSubordinateId] = useState();
  const [isSubordinateFormModalVisible, setIsSubordinateFormModalVisible] = useState(false);

  const getSubordinates = () => {
    userController
      .getSubordinates()
      .then((res) => res.json())
      .then((json) => {
        setDataSource(json);
      });
  };

  const deleteSubordinate = (subordinateId) => {
    userController
      .deleteSubordinate(subordinateId)
      .then((res) => res.json())
      .then((json) => {
        getSubordinates();
      });
  };

  const batchDeleteSubordinates = (subordinateIds) => {
    userController
      .batchDeleteSubordinates(subordinateIds)
      .then((res) => res.json())
      .then((json) => {
        getSubordinates();
      });
  };
  useEffect(() => getSubordinates(), []);

  const columns = [
    {
      title: 'Display Name',
      dataIndex: 'name',
      key: 'name',
      align: 'left',
    },
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
      align: 'left',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      align: 'left',
    },
    // {
    //   title: 'Comfort Temperature',
    //   dataIndex: 'comfortTemp',
    //   key: 'comfortTemp',
    //   align: 'left',
    // },
    // {
    //   title: 'Activeness',
    //   dataIndex: 'activeness',
    //   key: 'activeness',
    //   align: 'left',
    // },
    {
      title: 'Operation',
      dataIndex: '',
      align: 'center',
      width: 100,
      render: (value, record) => (
        <div className="operation-btn-gp">
          <Tooltip title="Edit">
            <EditOutlined
              onClick={() => {
                setIsSubordinateFormModalVisible(true);
                setSelectedSubordinateId(record.id);
              }}
            />
          </Tooltip>
          <IrreversiblePopconfirmWrapper onConfirm={() => deleteSubordinate(record.id)}>
            <Tooltip title="Delete">
              <CloseOutlined className="delete-btn" />
            </Tooltip>
          </IrreversiblePopconfirmWrapper>
        </div>
      ),
    },
  ];

  return (
    <Layout>
      <div className="SubordinateManagementPage">
        <h2>
          Subordinate Management{' '}
          <InfoButton title="Your subordinates would be authorized to access all of your projects." />
        </h2>
        <TableButtonGroup
          enabledButtons={['create', 'delete']}
          onCreate={() => setIsSubordinateFormModalVisible(true)}
          onDelete={() => batchDeleteSubordinates(selectedRowKeys)}
        />
        <Table
          rowKey="id"
          columns={columns}
          dataSource={dataSource}
          rowSelection={{
            selectedRowKeys,
            onChange: (selectedRowKeys) => setSelectedRowKeys(selectedRowKeys),
          }}
          pagination={{
            showSizeChanger: false,
          }}
          scroll={{ x: 640 }}
        />
      </div>
      <SubordinateFormModal
        selectedSubordinateId={selectedSubordinateId}
        visible={isSubordinateFormModalVisible}
        onCancel={(e) => {
          setIsSubordinateFormModalVisible(false);
          setSelectedSubordinateId(undefined);
        }}
        afterSubmit={() => {
          setIsSubordinateFormModalVisible(false);
          getSubordinates();
        }}
      />
    </Layout>
  );
};

export default SubordinateManagementPage;
